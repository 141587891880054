import {reactive} from "vue";

const messages = reactive({
    en: {
        message: {
            hello: 'hello world',
            error: 'request error!',
            notMore: 'no more!',
            content: 'article',
            video: 'video',
            live: 'live',
            videoError: 'video format error',
            top: 'top',
            referenning: 'Recommending',
        }
    },
    zh: {
        message: {
            hello: '你好世界',
            error: '请求错误!',
            notMore: '您已抵达最底层',
            content: '文章',
            video: '视频',
            live: '直播',
            videoError: '视频格式错误',
            top: '置顶',
            referenning: '正在为您推荐信息',
        }
    }
})
export default messages
