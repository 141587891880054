import axios from 'axios';

const baseURL = 'https://sg.singhot.com/';
axios.interceptors.request.use(
    (config) => {
        config.baseURL = "https://sg.singhot.com/api/";
        // 'Authorization'
        return config;
    },
    (error) => {
        // do something
        return Promise.reject(error);
    }
);
export default baseURL;