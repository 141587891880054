import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import { Tab, Tabs, List, PullRefresh, Cell, Empty, Locale, Swipe, SwipeItem, Image, Tag, NavBar, Popup, Collapse, CollapseItem, Button } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import i18n from "@/i18n";
import '@/config/http';



const app = createApp(App);

app.use(i18n)
app.use(router);
app.use(List);
app.use(PullRefresh);
app.use(Image);
app.use(Button)
app.use(Popup);
app.use(NavBar);
app.use(Swipe);
app.use(SwipeItem);
app.use(Tab);
app.use(Tag);
app.use(Collapse);
app.use(CollapseItem);
Locale.use('en-US', enUS);
app.use(Tabs);
app.use(Cell);
app.use(Empty);
app.mount('#app');

