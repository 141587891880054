import {createI18n} from "vue-i18n";

import messages from "@/language";

const i18n = createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'zh', // set fallback locale
    messages,
})
export default i18n;

